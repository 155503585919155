// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '../css/application.scss'
import Rails from '@rails/ujs'
Rails.start()

import Menu from './modules/menu.js'
import FixedBanner from './modules/fixed-banner.js'
//require("turbolinks").start()
require('@rails/activestorage').start()
//require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
require.context('../images', true)
// const imagePath = (name) => images(name, true)

window.addEventListener('load', function() {
  document.querySelectorAll('.js-header-menu').forEach(dom => {
    new Menu(dom)
  })

  const JS_FIXED_BOTTOM_BANNER_DOM = document.querySelector(
    '.js-fixed-bottom-banner'
  )
  if (JS_FIXED_BOTTOM_BANNER_DOM) {
    new FixedBanner(JS_FIXED_BOTTOM_BANNER_DOM)
  }
})
