class FixedBanner {
  constructor(targetDOM) {
    this.setParams(targetDOM)
    this.bindEvent()
  }
  setParams(targetDOM) {
    this.$banner = targetDOM
    this.$closeButton = document.querySelector('.js-fixed-bottom-banner-close')
    console.log(this.$closeButton)
  }
  bindEvent() {
    this.$closeButton.addEventListener('click', e => {
      e.preventDefault()
      this.close()
    })
  }
  close() {
    this.$banner.style.display = 'none'
  }
}
export default FixedBanner
