class Menu {
  constructor(targetDOM) {
    this.setParams(targetDOM)
    this.bindEvent()
  }
  setParams(targetDOM) {
    this.OPEN_STATE_CLASS = 'is-open'
    this.NO_SCROLL_CLASS = 'u-fixed'
    this.$btn = targetDOM
    this.$btns = document.querySelectorAll('.js-header-menu')
    this.$html = document.querySelector('html')
    this.$body = document.querySelector('body')
  }
  bindEvent() {
    this.$btn.addEventListener('click', e => {
      e.preventDefault()
      e.currentTarget.classList.contains(this.OPEN_STATE_CLASS)
        ? this.close()
        : this.open()
    })
  }
  open() {
    this.$btns.forEach(dom => {
      dom.classList.remove(this.OPEN_STATE_CLASS)
    })
    this.$btn.classList.add(this.OPEN_STATE_CLASS)
    this.$html.classList.add(this.NO_SCROLL_CLASS)
    this.$body.classList.add(this.NO_SCROLL_CLASS)
  }
  close() {
    this.$btn.classList.remove(this.OPEN_STATE_CLASS)
    this.$html.classList.remove(this.NO_SCROLL_CLASS)
    this.$body.classList.remove(this.NO_SCROLL_CLASS)
  }
}
export default Menu
